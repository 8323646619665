<template>
<div id="ausstellungen" class="container">
    <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Gruppen-Ausstellungen</h3>
    <ul class="flow-text" style="font-size: 16px">
      <li><i class="material-icons circle grey-text text-darken-2 pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;„Kunst in Alten Gemäuern“  Bad Bodend., Kreativ</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Hotel Dorint, Bad Neuenahr</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;LebensKunstMarkt Remagen seit 2004</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Annaberg, Polen „plein air“,  Ausstell.  2007</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Bonn-Hardtb. Kulturzentr.,BBK – Bonn „Neue“ 2007</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Mainz, Museumsnacht, BBK – RLP, „Neue“ 2010</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Freie Ateliergruppe Villa Heros,  „WIR“ 2010, 2013(KüFo Remagen)</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Beteiligung an KüFo Projekt „Remagener Kunstfahnen“ Titel: „WORTGEWALT IST AUCH GEWALT“) 2014</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Mitglieder-Ausstell. KüFo 2014 und 2015</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;ART AHR „Parallelwelten“ 2014</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Synagoge Bad Neuenahr-Ahrweiler 2014 „Gegensätze – Überschneidungen“ (4 Künstlerinnen zeigen…)</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Kunsthaus  Rheinlicht, Projekt „weiß schwarz  + “,  Ausstellung In der alten Druckerei Sinzig  (ehem. Verlag Krupp) 2016</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;AHRTkomm,  Alte Druckerei,   2018</li>
    </ul>
    <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Einzel-Ausstellungen</h3>
    <ul class="flow-text" style="font-size: 16px">
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;2012  Remagen, Galerie M.A.S.H. „WELLEN-GANG I“</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;2013  Sinzig, Kunst im Gewölbe – Zehnthof  „WELLEN-GANG II“</li>
      <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>2014  Bonn - BGO,  Galerie Wohnraum-Kunst Martine Seibert-Raken, „WELLEN-GANG III“</li>
    </ul>


    <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Atelier-Ausstellungen</h3>
    <ul class="flow-text" style="font-size: 16px">
      OAT,  jährl. Zu  LKM  Remagen, sowie zum jährl.
      Herbst-Kunst-Salon, Remagen
    </ul>

</div>
</template>
<script>
export default {
  name: "Ausstellungen",
  mounted() {
    this.$nextTick(() => {
      $('.sidenav').sidenav('close')
    })
  }
}
</script>
<style scoped>
ul li{
  line-height: 1.7em;
}
</style>

