<template>
<div id="footer" style="margin-left: 2vw">
  <router-link :to="{ name: 'impressum' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Impressum</router-link>
</div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped>

</style>
