<template>
  <div id="vita" class="container">
    <section>
      <div class="row row-1">
        <div class="col m6">
        <!--div class="col m6 hide-on-small-only"-->
          <div class="img-content">
            <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Evelyn Klein</h3>
            <p><router-link :to="{ name: 'malerei'}" class="mehr-link" title="Werkfotos">Malerei</router-link>
               / <router-link :to="{ name: 'grafik'}" class="mehr-link" title="Werkfotos">Grafik</router-link>
               / <router-link :to="{ name: 'zeichnen'}" class="mehr-link" title="Werkfotos">Zeichnen</router-link>
            </p>
          </div>
        </div>
        <div class="col m6" style="margin-top: 5vh">
          <img class="responsive-img" src="../assets/images/vita/atelier_evelyn_klein.jpg" title="Evelyn Klein">
        </div>
      </div>
    </section>
    <section>
      <h5 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Kurzvita</h5>
      <ul class="flow-text" style="font-size: 16px">
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Kunststudium: Zeichnen, Illustration, Malerei (bei den Dozenten Schaffmeister/Krämer, Wolff, Kohlsch.- Richter,
          Strack u.a.)</li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;Ab 2000  künstler. Fortbildung in Europ. Kulturzentrum, Galerie Rolandseck,
          künstler. Arbeit mit Jugendlichen an GT-Schule und in Work-Shops</li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;2006 Mitarbeit zur Etablierung des 1. Kunstvereins in „Villa Heros“, Remagen</li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;2010 Sommerakademie Bad Honnef, freie Malerei,  Kl. Prof. M. Lüpertz </li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;2013 Seminar Kloster Steinfeld , Kalligraphie-Tuschmalerei bei Prof. Qi Yang</li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;seit 2010 Mitglied  in BBK -  RLP,  Mitgl. In  KüFo  Remagen</li>
        <li><i class="material-icons circle grey-text text-darken-2  pink lighten-3 tiny">&nbsp;fiber_manual_record</i>&nbsp;div. Studienreisen im In- u. Ausland</li>
      </ul>
    </section>
  </div>
</template>
<script>
export default {
  name: "Vita",
  mounted() {
    this.$nextTick(() => {
      $('.sidenav').sidenav('close')
    })
  }
}
</script>

<style scoped>
ul li{
  line-height: 1.7em;
}
</style>
