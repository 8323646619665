<template>
  <div id="malerei" class="container content" style="margin-top: 5vh;">
    <ul class="row">
      <li class="col s12 m6">
        <h4 class="pink-text text-lighten-3 center flow-text" style="text-shadow: 3px 3px 3px #000">Malerei: Aquarell<router-link :to="{ name: 'aquarell'}" class="mehr-link">&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">Mehr ...</span></router-link></h4>
        <img class="materialboxed responsive-img" src="../assets/images/gallery/malerei/l_aqua.jpg"  title="Portrait">
      </li>
      <li class="col s12 m6">
        <h4 class="pink-text text-lighten-3 center flow-text" style="text-shadow: 3px 3px 3px #000;">Malerei: Acryl / Öl<router-link :to="{ name: 'acryl'}" class="mehr-link">&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">Mehr ...</span></router-link></h4>
        <img class="materialboxed responsive-img" src="../assets/images/gallery/malerei/l_acryl_lebensspuren.jpg"  title="Lebensspuren Turkis">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Malerei",
  methods: {
    topTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })
    this.topTop()
  }
}
</script>

<style scoped lang="scss">
</style>
