<template>
  <div id="app" class="grey-text text-lighten-3">
    <navigation-view></navigation-view>
    <router-view/>
    <footer-view></footer-view>
  </div>
</template>
<script>
import NavigationView from '@/views/NavigationView'
import FooterView from '@/views/FooterView'
export default {
  components: {
    NavigationView,
    FooterView
  }
}
</script>

<style lang="scss">
body
{
  background-color: #616161;
  width: 100%;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Tahoma, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.content{
  background-size: cover;
  min-height: calc(80vh);
}

@media screen and (max-width: 670px){
  .content{
    min-height: 500px;
  }
}
#footer {
  width:100%;
}
.mehr-link{
  color: #fff;
}
.mehr-link:hover{
  color: #f48fb1;
}
</style>
