<template>
  <div id="acryl" class="container content" style="margin-top: 5vh;">
    <h3 class="center" style="margin-bottom: 50px">
      <span class="pink-text text-lighten-3 flow-text"  style="text-shadow: 3px 3px 3px #000">Malerei/ Acryl & Öl</span> <span style="font-size: 16px">(Auswahl)</span>
    </h3>
    <ul class="row" style="width: 80%">
      <li class="col s12 m4">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/malerei/acryl/evelyn_klein_1.jpg" title="Lebensformen Blau">
      </li>
      <li class="col s12 m4">
        <img class="materialboxed responsive-img" style="margin-bottom: 12px"  src="../assets/images/gallery/malerei/acryl/evelyn_klein_2.jpg" title="Lebensformen Rose">
      </li>
      <li class="col s12 m4">
        <img class="materialboxed responsive-img" style="margin-bottom: 12px"   src="../assets/images/gallery/malerei/acryl/evelyn_klein_3.jpg" title="Lebensspuren Turkis">
      </li>
      <li class="col s12 m6">
        <img class="materialboxed responsive-img  to-top" src="../assets/images/gallery/malerei/acryl/landschaft_1.jpg" title="Seelen-Landschaft I">
      </li>
      <li class="col s12 m6">
        <img class="materialboxed responsive-img  to-top" src="../assets/images/gallery/malerei/acryl/landschaft_2.jpg" title="Seelen-Landschaft II">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Acryl",
  methods: {
    topTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })
    this.topTop()
  }
}
</script>

<style scoped>
.to-top{
  margin-top: 1vh;
}
</style>
