<template>
  <header>
    <nav class="nav-wrapper grey darken-3 z-depth-3">
      <div>
        <router-link  :to="{ name: 'home'}" class="brand-logo pink-text text-lighten-3 z-depth-0" title="to main page ...">
          <span style="margin-left: 2vw; text-shadow: 3px 3px 3px #000" class="flow-text hide-on-med-and-down">ARTE INKOGNITA <span style="font-size: .6em">by Evelyn Klein</span></span>
          <span style="text-shadow: 3px 3px 3px #000; font-size: .5em" class="hide-on-large-only">ARTE INKOGNITA <span style="font-size: .6em">by Evelyn Klein</span></span>
        </router-link>
        <a href="#" class="sidenav-trigger" data-target="mobile-menu">
          <i class="material-icons">menu</i>
        </a>
        <ul class="right hide-on-med-and-down">
          <li>
            <router-link :to="{ name: 'home'}" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ausstellungen' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Ausstellungen</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'vita' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Vita</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'kontakt' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Kontakt</router-link>
          </li>
        </ul>
        <ul class="sidenav grey darken-4 z-depth-0 hide-on-large-only" id="mobile-menu">
          <li>
            <router-link :to="{ name: 'home'}" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Home</router-link>
          </li>
          <li>
          <li>
            <router-link :to="{ name: 'malerei'}" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Malerei</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'grafik'}" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Grafik</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'zeichnen'}" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Zeichnen</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ausstellungen' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Ausstellungen</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'vita' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Vita</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'kontakt' }" class="transparent z-depth-0 pink-text text-lighten-3 waves-effect waves-black">Kontakt</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'NavigationView',
  components: {
    //
  },
  mounted() {
    this.$nextTick(() => {
      $('.sidenav').sidenav()
    })
  },
  methods: {
    goToHome() {
      const element = this.$refs[home];
      console.log(element)
    }
  }
}
</script>

<style scoped lang="scss">
.side-nav {
  padding: 30px;
}

logo {
  left: 50%;
  transform: translateX(-50%);
}

.slider .btn {
  margin-top: 20px;
}

.section-search input {
  padding: 5px !important;
  font-size: 18px !important;
  width: 90% !important;
  border: 3px solid #f4f4f4 !important;
}

/* CUSTOM CSS SO THE AUTOCOMPLETE WON'T EXTEND THE AREA ON OPENING; WE WANT IT TO GO OUTSIDE OF IT AND STAY THE SAME HEIGHT */
.autocomplete-content {
  position: absolute;
  width: 100%;
  text-align: center;
}

.section-icons {
  padding-top: 40px;
}

.section-popular h4,
.section-gallery h4,
.form-header {
  margin-bottom: 30px;
}

.section-follow .fa-4x {
  margin: 5px 10px;
}

.section-contact ul {
  margin-top: 60px;
}
a.pink-text.text-lighten-3:hover, ul a.pink-text.text-lighten-3.router-link-exact-active{
  color: #f06292  !important;
}
</style>
