<template>
  <div id="grafik" class="container content" style="margin-top: 5vh">
    <h3 class="center" style="margin-bottom: 50px; margin-left: -10%">
      <span class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Grafik</span> <span
        style="font-size: 16px">(Auswahl)</span>
    </h3>

    <div class="row center">
      <div class="col s12 m4 vertical-align-center" style="margin-top: 1px; margin-bottom: 5px">
        <div class="row">
          <img class="materialboxed responsive-img" src="../assets/images/gallery/grafik/Klang-Wellen_blau2.jpg"
               title="Klang-Welle &quot;Moll&quot;">
        </div>
        <div class="row">
          <img class="materialboxed responsive-img" src="../assets/images/gallery/grafik/welle_2.jpg"
               title="Welle II">
        </div>
      </div>
      <div class="col s12 m4 vertical-align-center" style="margin-top: 1px; margin-left: 20px;">
        <div class="row">
          <img class="materialboxed responsive-img" src="../assets/images/gallery/grafik/welle_1.jpg"
               title="Welle I">
        </div>
        <div class="row">
          <img class="materialboxed responsive-img" src="../assets/images/gallery/grafik/welle_rot.jpg"
               title="Klang-Welle &quot;Dur&quot;">
        </div>
      </div>
      <div class="col s12 m2 vertical-align-center" style="margin-left: 20px;">
        <div class="row">
          <img class="materialboxed responsive-img" style="width: 88%;" src="../assets/images/gallery/grafik/yin_yang1.jpg"
               title="Yin">
        </div>
        <div class="row">
          <img class="materialboxed responsive-img" style="width: 88%;" src="../assets/images/gallery/grafik/yin_yang2.jpg"
               title="Yang">
        </div>
      </div>
    </div>
    <div class="row">
      <h5 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">WELLEN-GANG, FARB-WELLEN, ENERGIE-WELLEN,KLANG-WELLEN</h5>
      <div class="klang-text flow-text col s12 m10"  style="font-size: 16px;">
        Meine Arbeit bezieht sich weitgehend auf die Natur , den darin Lebenden Menschen, seine Evolution und die Potentiale die er zum Über-Leben benötigt.
        <br><br>„WELLEN-GANG, FARB-WELLEN, ENERGIE-WELLEN, KLANG-WELLEN“<br>Inspiriert zu diesem Thema wurde ich durch lange Beschäftigung und Beobachtung von Wellen-Bewegungen, sowohl in der Natur, als auch in uns selbst.<br>
        Die Parallele des Wellen-Ganges von Meer u. Wasser im „Außen“ zum Wellengang im „Inneren“ des Menschen, wurde in meinen Augen immer deutlicher, nicht zuletzt wohl deshalb, da der Mensch zu einem hohen Anteil selbst aus Wasser besteht.<br>
        Wellen beinhalten und tragen Informationen des Lebens weiter, Möglichkeiten, die sich zu Wirklichkeiten des Lebens formen und verdichten können.<br>
        So sprechen wir von Klangwellen, Lichtwellen, Fieberwellen,  Sympathie-Wellen u. vielen mehr.
        Diese flüchtigen, unsichtbaren Wellen versuche ich nun, ebenso wie die sichtbaren, in Form, Farbe und Tusche, oft als Symbole, darzustellen ,ja  „festzuhalten“. So können sie auch zu einer „Metapher“ für das „Böse“ werden, wie es im Buch  „Die Welle“ von  M o r t o n R h u e beeindruckend beschrieben ist, werden.<br>
        -Lieblich plätschernd, dahin gleitend - Licht einfangend und spiegelnd –<br>
        ebenso wie - peitschend, stürmend, gnadenlos überrollend, einstürzend, gegeneinander aufbäumend -  wie ein Zunami.<br>
        Mein künstl. Ausdruck findet sowohl in der Tusch - als auch Acryl-u. Öl-malerei,  zwischen Kalligraphie und Action-Painting angesiedelt, eine ge-eignete Form, um diese „Botschaften“ zu übermitteln. Die gestischen Zeichen sind oft selbst die Botschaft, sie erzählen nicht nur von Wellen in allem, sondern stellen diese zugleich dar.<br><br>
        Der Betrachter ist jedoch eingeladen, seiner eigenen Phantasie, seinen Assoziationen und Interpretationen freien Lauf zu lassen.<br><br>
        Evelyn Klein
      </div>
      </div>
  </div>

</template>

<script>
export default {
  name: "Grafik",
  methods: {
    topTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })
    this.topTop()
  }
}
</script>

<style scoped>
.row .klang-text{
  padding-left: 0;
}

</style>
