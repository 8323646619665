<template>
  <div id="aquarell" class="container content" style="margin-top: 5vh;">
    <h3 class="center" style="margin-bottom: 50px">
      <span class="pink-text text-lighten-3 flow-text"  style="text-shadow: 3px 3px 3px #000">Marerei/ Aquarell</span> <span style="font-size: 16px">(Auswahl)</span>
    </h3>
    <ul class="row center">
      <!--li class="col s12 m1">
      </li-->
      <li class="col s12 m5">
        <img class="materialboxed responsive-img" style="width: 87%" src="../assets/images/gallery/malerei/aquarell/aquarell_4.jpg" title="Toscana">
      </li>
      <li class="col s12 m5">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/malerei/aquarell/aquarell_2.jpg" title="Rheinsicht">
      </li>
    </ul>
    <ul class="row center">
      <!--li class="col s12 m1">
      </li-->
      <li class="col s12 m3">
        <img class="materialboxed responsive-img" style="margin-top: 3vh;" src="../assets/images/gallery/malerei/aquarell/aquarell_1.jpg" title="Brücke am Meer">
      </li>
      <li class="col s12 m3">
        <img class="materialboxed responsive-img  to-top" style="margin-left: 3vw;" src="../assets/images/gallery/malerei/aquarell/aquarell_3.jpg" title="Rügen">
      </li>
      <li class="col s12 m2">
        <img class="materialboxed responsive-img  to-top" style="margin-left: 11vw;" src="../assets/images/gallery/malerei/aquarell/aquarell_5.jpg" title="Baum an der Ahr">
      </li>
    </ul>


  </div>
</template>

<script>
export default {
  name: "Aquarell",
  methods: {
    topTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })
    this.topTop()
  }
}
</script>

<style scoped>
.to-top{
  margin-top: 1vh;
}
</style>
