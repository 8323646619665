<template>
  <div id="zeichnen" class="container content" style="margin-top: 5vh">
    <h3 class="center" style="margin-bottom: 50px">
      <span class="pink-text text-lighten-3 flow-text"  style="text-shadow: 3px 3px 3px #000">Zeichnen</span> <span style="font-size: 16px">(Auswahl)</span>
    </h3>
    <ul class="row">
      <li class="col s12 m3">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/zeichnen/zeichnung_1.jpg" title="Zeichnung in Tusche">
      </li>
      <li class="col s12 m3">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/zeichnen/zeichnung_2.jpg" title="Zeichnung in Kohle">
      </li>
      <li class="col s12 m3">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/zeichnen/zeichnung_3.jpg" title="Indian-Summer in Kreide">
      </li>
      <li class="col s12 m3">
        <img class="materialboxed responsive-img"  style="width: 98%;" src="../assets/images/gallery/zeichnen/zeichnung_6.jpg" title="Mädchen-Portrait in Kreide">
      </li>
      <li class="col s12 m6" style="margin-top: 3vh;">
        <img class="materialboxed responsive-img" style="width: 94%;" src="../assets/images/gallery/zeichnen/zeichnung_4.jpg" title="Rügen-Buntstift">
      </li>
      <li class="col s12 m3 to-top" style="margin-top: 3vh;">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/zeichnen/zeichnung_7.jpg" title="Zeichnung in Kreide">
      </li>
      <li class="col s12 m3 to-top to-top7" style="margin-top: 3vh;">
        <img class="materialboxed responsive-img" src="../assets/images/gallery/zeichnen/zeichnung_5.jpg" title="Kinder-Portrait in Kreide">
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "Zeichnen",
  methods: {
    topTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })

    this.topTop()
  }
}
</script>

<style scoped>
@media screen and (max-width: 592px) {
  .to-top{
    margin-top: 3vh;
  }
}
@media screen and (min-width: 592px) {
  .to-top7{
    margin-top: 1vh;
  }
}

</style>
