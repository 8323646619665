import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Malerei from '@/components/Malerei.vue'
import Acryl from '@/components/Acryl.vue'
import Aquarell from '@/components/Aquarell.vue'
import Grafik from '@/components/Grafik.vue'
import Zeichnen from '@/components/Zeichnen.vue'
import Ausstellungen from '@/components/Ausstellungen.vue'
import Vita from '@/components/Vita.vue'
import Kontakt from '@/components/Kontakt.vue'
import Impressum from '@/components/Impressum.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/ausstellungen',
    name: 'ausstellungen',
    component: Ausstellungen
  },
  {
    path: '/vita',
    name: 'vita',
    component: Vita
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: Kontakt
  },
  {
    path: '/malerei',
    name: 'malerei',
    component: Malerei
  },
  {
    path: '/malerei/aquarell',
    name: 'aquarell',
    component: Aquarell
  },
  {
    path: '/malerei/acryl',
    name: 'acryl',
    component: Acryl
  },
  {
    path: '/grafik',
    name: 'grafik',
    component: Grafik
  },
  {
    path: '/zeichnen',
    name: 'zeichnen',
    component: Zeichnen
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
