<template>
    <div id="contact" class="container content">
      <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Kontakt</h3>
      <p>
        <a class="moz-txt-link-abbreviated white-text" href="mailto:evelyna-klein@t-online.de">Kontakt mit Evelyn Klein per Email <b>(Anklicken)</b></a>
      </p>
    </div>
</template>

<script>
export default {
  name: "Kontakt",
  mounted() {
    this.$nextTick(() => {
      $('.sidenav').sidenav('close')
    })
  }
}
</script>

