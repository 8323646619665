<template>
  <div ref="home" id="home" class="container content" style="margin-top: 5vh; width: 55%">
    <div class="row row-1 valign-wrapper">
      <div class="col m6 hide-on-small-only">
        <div class="valign">
          <h3 class="pink-text text-lighten-3" style="text-shadow: 3px 3px 3px #000">Malerei</h3>
          <router-link :to="{ name: 'malerei'}"  class="mehr-link">Mehr ...</router-link>
        </div>
      </div>
      <div class="col m6">
        <div class=" col hide-on-med-and-up">
          <h4 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Malerei<router-link :to="{ name: 'malerei'}" class="mehr-link">&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">Mehr ...</span></router-link></h4>
        </div>
        <img class="materialboxed responsive-img" src="../assets/images/home/lebensspuren.jpg"  title="Lebensspuren">
      </div>
    </div>
    <div class="row row-2 valign-wrapper">
      <div class="col m6">
        <div class=" col hide-on-med-and-up">
          <h4 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Grafik<router-link :to="{ name: 'grafik'}" class="mehr-link">&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">Mehr ...</span></router-link></h4>
        </div>
        <img class="materialboxed responsive-img" src="../assets/images/home/Klang-Wellen_blau2.jpg"  title="Klangwellen">
      </div>
      <div class="col m6 hide-on-small-only">
        <div class="valign">
          <h3 class="pink-text text-lighten-3 " style="text-shadow: 3px 3px 3px #000">Grafik</h3>
          <router-link :to="{ name: 'grafik'}"  class="mehr-link">Mehr ...</router-link>
        </div>
      </div>
    </div>
    <div class="row row-3 valign-wrapper">
      <div class="col m6 hide-on-small-only">
        <div class="valign">
          <h3 class="pink-text text-lighten-3" style="text-shadow: 3px 3px 3px #000">Zeichnen</h3>
          <router-link :to="{ name: 'zeichnen'}"  class="mehr-link">Mehr ...</router-link>
        </div>
      </div>
      <div class="col m6">
        <div class=" col hide-on-med-and-up">
          <h4 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Zeichnen<router-link :to="{ name: 'zeichnen'}" class="mehr-link">&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">mehr ...</span></router-link></h4>
        </div>
        <img class="materialboxed responsive-img" src="../assets/images/home/verirrte-seelen.jpg"  title="Verirrte Seelen">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    //
  },
  mounted() {
    this.$nextTick(() => {
      $('.materialboxed').materialbox();
      $('.sidenav').sidenav('close')
    })
  }
}
</script>
<style>
</style>
