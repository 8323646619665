<template>
  <div id="impressum" class="container content">
    <h3 class="pink-text text-lighten-3 flow-text" style="text-shadow: 3px 3px 3px #000">Impressum</h3>
    <p><b>Website Inhaberin: </b>Evelyn Klein</p>
    <p><b>Email: </b><a href="mailto:evelyna-klein@t-online.de">Evelyn Klein</a></p>

    <p><b>Verantwortlich für die Inhalte dieses Webauftritts: </b>Evelyn Klein</p>

    <p><b>Haftungsausschluss</b><br/>
      Die auf dieser Website enthaltenen Daten wurden mit größtmöglicher Sorgfalt und nach&nbsp;bestem Wissen und
      Gewissen zusammengestellt. Diese Seite enthält Links, die auf die Internetseiten anderer Anbieter verweisen. Wenn
      Sie diesen Links folgen, verlassen Sie das Angebot von Evelyn Klein. Es wird weder die Verantwortung übernommen
      für die Richtigkeit dieser Seiten noch wird deren Inhalt oder die dort vertretene Meinung unbedingt geteilt.<br/>
    </p>
    <p><b>Datenschutzbestimmungen</b><br/>
      Der Inhalt dieser Website ist urheberrechtlich geschützt.<br/>
      Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Fotos und Texten, bedürfen
      einer
      vorherigen Zustimmung von Evelyn Klein.
    </p>
    <p><b>Entwicklung - Administration</b><br/>
      <a href="mailto:jarrar@vtc-net.de">VTC-Net</a>, Diplom-Ingenieur Ibrahim Jarrar
    </p>
  </div>
</template>

<script>
export default {
  name: "Impressum",
  mounted() {
    this.$nextTick(() => {
      $('.sidenav').sidenav('close')
    })
  }
}
</script>

<style scoped>
</style>
